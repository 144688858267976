import { isSSOProvider } from 'settings/constants/auth'

export const getFirstNameAndLastName = (displayName = '', providerId, firstName, lastName) => {
  if (isSSOProvider(providerId) && firstName) {
    return { firstName, lastName }
  }

  const [first, ...last] = displayName.split(' ')
  return { firstName: first, lastName: last.join(' ') }
}
