import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { FacebookAuthProvider, signInWithPopup } from 'firebase/auth'
import { Box, Button, useTheme, Alert, Typography } from '@mui/material'

import Constants from 'Constants'
import { get } from 'utils/lodash'

const { MEDIA_SERVER } = Constants

const FacebookLogin = ({ auth, onRegisterUser }) => {
  const theme = useTheme()
  const [hasError, setHasError] = useState(false)

  const handleLoginWithFacebook = async () => {
    try {
      const authResult = await signInWithPopup(auth, new FacebookAuthProvider())
      const { user, _tokenResponse } = authResult
      onRegisterUser({
        user,
        additionalUserInfo: {
          providerId: get(_tokenResponse, 'providerId'),
          profile: {
            given_name: get(_tokenResponse, 'firstName', ''),
            family_name: get(_tokenResponse, 'lastName', ''),
          },
        },
      })
    } catch (error) {
      if (error.code === 'auth/account-exists-with-different-credential') {
        setHasError(true)
      }
    }
  }

  return (
    <>
      {hasError && (
        <Alert severity="error" sx={{ mx: 3, mb: 2, alignItems: 'center' }}>
          <Typography variant="h6" fontWeight={400}>
            You already have an account with Google. Please sign in with Google to continue.
          </Typography>
        </Alert>
      )}
      <Box position="relative" id="custom-auth-container">
        <Button
          onClick={handleLoginWithFacebook}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            p: 0,
            minWidth: 48,
            width: 48,
            height: 48,
            boxShadow: 'none',
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: '50%',
            position: 'absolute',
            zIndex: theme.zIndex.appBar,
            left: '52%',
          }}
        >
          <img alt="" width={24} height={24} src={`${MEDIA_SERVER}social-icons/auth/Facebook.svg`} />
        </Button>
      </Box>
    </>
  )
}

FacebookLogin.propTypes = {
  auth: PropTypes.object,
  onRegisterUser: PropTypes.func.isRequired,
}

export default FacebookLogin
